import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'developers.snap.com',
  theme: {},

  redirects: {
    hostRedirects: {
      'developer.snapchat.com': {
        url: 'https://developers.snap.com',
        isPermanent: true,
      },
      'developer.snap.com': {
        url: 'https://developers.snap.com',
        isPermanent: true,
      },
      'developers.snapchat.com': {
        url: 'https://developers.snap.com',
        isPermanent: true,
      },
    },
  },
  globalNavProps: {
    defaultGroupKey: 'developers',
  },
  trackingSettings: {
    cookieDomain: 'snap.com',
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.DEVELOPER_SNAP },
      {
        vendor: LoggingVendor.GOOGLE,
        googleTagManagerId: 'GTM-PB72WQ3',
        googleAnalyticsId: '41740027-48',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'developer-snap-com',
        dsn: 'https://ffd8eb0c8d534ae3948038968480d408@sentry.sc-prod.net/133',
      },
    ],
  },
};
